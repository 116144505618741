import React, { useRef, useState } from "react";

import usaFlag from "../../assets/images/usa-flag.png";
import spanishFlag from "../../assets/images/spanish-flag.png";
import { useOutsideClickDetection } from "../../helpers/useOutsideClickDetection";
import { useTranslation } from "react-i18next";
import useGetParameterByName from "../../helpers/useGetParameterByName";
import { useLocation, useNavigate } from "react-router";

import "./LanguageSelect.css";

const LanguageSelect = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const langFromUrl = useGetParameterByName("lang");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    flag:
      langFromUrl === ""
        ? usaFlag
        : langFromUrl === "EN"
        ? usaFlag
        : spanishFlag,
    keyword: langFromUrl === "" ? "EN" : langFromUrl === "EN" ? "EN" : "ES",
  });
  const dropdownRef = useRef(null);

  const { i18n } = useTranslation();

  const langItems = [
    { flag: usaFlag, keyword: "EN" },
    { flag: spanishFlag, keyword: "ES" },
  ];

  const closeLangSelect = () => {
    setIsOpen(false);
  };

  const onLanguageSelect = (flag: any, keyword: any) => {
    i18n
      .changeLanguage(keyword)
      .then((res: any) => {
        setSelectedLanguage({ flag: flag, keyword: keyword });
        setIsOpen(false);
        urlParams.set("lang", keyword);
        navigate({
          search: urlParams.toString(),
        });
      })
      .catch((err: any) => console.error(err));
  };

  useOutsideClickDetection(dropdownRef, closeLangSelect);

  return (
    <div className="LanguageSelect">
      <div
        className="LanguageSelect__head"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <img
          alt="Flag"
          className="LanguageSelect__head-flag"
          src={selectedLanguage.flag}
        />
        <div className="LanguageSelect__head-label">
          {selectedLanguage.keyword}
        </div>
        <svg
          className="LanguageSelect__head-chevron"
          width="11"
          height="6"
          viewBox="0 0 11 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656 1.25832C-0.0752187 0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409 -0.0719468 1.01413 -0.0719468 1.31503 0.21584L5.49998 4.22061L9.68496 0.21598C9.98584 -0.0718069 10.4735 -0.0718069 10.7742 0.21598C11.0753 0.503767 11.0753 0.970553 10.7742 1.25846L6.04455 5.78436C5.89417 5.92813 5.69705 6 5.49998 6Z"
            fill="#111827"
          />
        </svg>
      </div>
      {isOpen && (
        <div ref={dropdownRef} className="LanguageSelect__body">
          {langItems.map((item, index) => (
            <div
              key={index}
              className="LanguageSelect__body-item"
              onClick={() => {
                onLanguageSelect(item.flag, item.keyword);
              }}
            >
              <img
                alt="Flag"
                className="LanguageSelect__body-flag"
                src={item.flag}
              />
              <div className="LanguageSelect__body-label">{item.keyword}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelect;
