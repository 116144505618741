import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import useGetParameterByName from "./helpers/useGetParameterByName";
import translationsInEng from "./locales/en/translation.json";
import translationsInSpanish from "./locales/es/translation.json";

import "./App.scss";

function App() {
  const langFromUrl = useGetParameterByName("lang");

  const resources = {
    EN: {
      translation: translationsInEng,
    },
    ES: {
      translation: translationsInSpanish,
    },
  };

  i18n.use(initReactI18next).init({
    resources,
    lng: langFromUrl === "" ? "EN" : langFromUrl,
    debug: false,
    fallbackLng: "ES",
    interpolation: {
      escapeValue: false,
    },
    ns: "translation",
    defaultNS: "translation",
  });
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </I18nextProvider>
    </div>
  );
}

export default App;
